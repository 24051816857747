import { useQuery } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { FormType } from '../../../../models/FormTypes';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import ClientTemplateFormService from '../../../../services/ClientTemplateFormService';
import TemplateFormService from '../../../../services/TemplateFormService';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import { Option } from '../../../Option';
import { Item } from '../../../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import { OpenInNewTabValueRendererWrapper } from '../../../shared/form-control/DropdownSharedComponnets';
import ActionEditorBase from '../ActionEditorBase';
import { ChildFormRenderType } from '../child-form/ChildFormRenderType';
import { createFormTemplateSource } from '../FormTemplateSource';
import TranslatableInput from '../../../shared/form-control/TranslatableInput';

const PreSelectedFormEditor: FC<EditorProps> = (props) => {
  const { action, patchData, form, builderBaseUrl, patchTranslations } = props;
  const { translations } = action;
  const { templateFormId, renderType = ChildFormRenderType.External } = action?.data || {};

  const currentClient = useRecoilValue(currentClientAtom);

  const { t } = useTranslation('form-builder');

  const templateService = useMemo(() => {
    if (currentClient) {
      return new ClientTemplateFormService(currentClient.id);
    }
    return TemplateFormService;
  }, [currentClient]);

  const {
    data: templates = [],
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['form-templates', action.id],
    queryFn: () =>
      // TEMP: always get the latest forms because we store undefined as the version temporarily
      templateService
        .getAllTemplates({
          includeArchived: true,
          latestOnly: true,
          types: [FormType.SubFormWithApproval, FormType.SubForm, FormType.Asset],
        })
        .then((res) => {
          return res.data.filter((template) => template.id !== form.id);
        }),
  });

  const templateForms = useMemo(() => {
    return createFormTemplateSource(templates);
  }, [templates]);

  const selectedTemplateForm: Item | undefined = useMemo(
    () =>
      templateForms
        .map((x) => ('options' in x ? x.options : x))
        .flat()
        .find((x) => (x as Item).id === templateFormId) as Item,
    [templateFormId, templateForms],
  );

  const onTemplateChanged = (option: Item) => {
    // TEMP: store undefined as version so it always provisions the latest version
    patchData({ templateFormId: option.id, templateFormVersion: undefined }); // option.value });
  };

  const renderTypes: Option<string, string>[] = useMemo(
    () => [
      { text: ChildFormRenderType.Inline, id: ChildFormRenderType.Inline, value: ChildFormRenderType.Inline },
      { text: ChildFormRenderType.External, id: ChildFormRenderType.External, value: ChildFormRenderType.Inline },
    ],
    [],
  );

  const onRenderTypeChanged = (option: Option<string, string | number>) => {
    patchData({ renderType: option.id });
  };

  const formLinkProvider = useCallback(
    (item: Item) => {
      return `${builderBaseUrl}/${item.id}/${item.value}`;
    },
    [builderBaseUrl],
  );

  return (
    <div data-cy="pre-selected-form-editor">
      <ActionEditorBase {...props}>
        <TranslatableInput
          data-cy="form-mode"
          label={t('action-properties.subform.form-title')}
          placeholder={t('action-properties.subform.form-title')}
          translationKey="newFormTitle"
          translations={translations ?? {}}
          onTranslationsChange={patchTranslations}
        />

        <DropdownSelect
          label={t('action-properties.subform.template-form')}
          data-cy="template-form"
          placeholder={t('action-properties.subform.template-form')}
          value={{
            id: templateFormId,
            text: selectedTemplateForm?.text || '',
            value: selectedTemplateForm?.value as number,
            disabled: selectedTemplateForm?.disabled,
          }}
          options={templateForms}
          onChange={onTemplateChanged}
          searchable
          customListRenderer={OpenInNewTabValueRendererWrapper(formLinkProvider)}
          isFetching={isFetching}
          onOpen={refetch}
        />
        <DropdownSelect
          label={t('action-properties.subform.render-mode')}
          data-cy="render-mode"
          placeholder={t('action-properties.subform.render-mode')}
          value={{ id: renderType, text: renderType, value: renderType }}
          options={renderTypes}
          onChange={onRenderTypeChanged}
        />
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />
    </div>
  );
};

export default PreSelectedFormEditor;
